import React from "react"
import { FormularioWrapper, Img } from "./style"
import Formulario from "../formulario"
import img from "../../assets/images/mapa.jpg"
const index = () => {
  return (
    <FormularioWrapper>
      <div
      data-aos="fade-right"
      data-aos-duration="1500"
      >
        <Img src={img} alt="mapa de intramet" />
      </div>
      <Formulario title="Contactanos" text="Contacta con nosotros"></Formulario>
    </FormularioWrapper>
  )
}

export default index
