import styled from "styled-components"
export const FormularioWrapper = styled.div`
  padding: 2.5rem 0;
  display: flex;
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
  align-items: center;
  justify-content: center;
  max-width: 1440px;
  margin: 0 auto;
  .container {
    max-width: 100%;
    width: 100%;
    form {
      width: 100%;
    }
  }
@media (max-width: 800px){
    grid-template-columns:1fr;
}
`
export const Img = styled.img`
  width: 100%;
`
