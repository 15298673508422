// esta es la pagina de gracias de contacto
import React from "react"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import BlockFormulario from "../components/contacto"
export default function Gracias() {
  return (
    <>
      <Helmet>
        <title>Esta es la pagina de Contacto</title>
        <meta name="description" content="Esta es la pagina de Contacto"></meta>
      </Helmet>
      <Layout>
        <BlockFormulario></BlockFormulario>
      </Layout>
    </>
  )
}
